.popup__input {
    font-style: normal;
    font-size: 1.1vh;
    line-height: 17px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    width: 358px;
    padding: 0;
    padding-bottom: 11px;
    margin-bottom: 30px;
}

@media screen and (max-width: 720px) {
    .popup__input {
        padding: 0;
        width: 238px;
        margin-bottom: 33px;
        padding-bottom: 13px;
    }
}