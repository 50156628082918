.header__bar_link_zal {
    margin-top: 10px;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    border: none;
    text-decoration: none;
    font-size: 100%;
    text-align: center;
    color: rgb(73, 213, 255);
    text-shadow:
        0 0 10px rgb(73, 213, 255),
        0 0 82px rgb(73, 213, 255),
        0 0 151px rgb(73, 213, 255);
}
