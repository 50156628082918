.container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1200px;
    /* Ограничиваем максимальную ширину контейнера для лучшей симметрии */
}

.title {
    -webkit-box-flex: 1;
    color: #ffffff;
    -ms-flex: 1 100%;
    flex: 1 100%;
    font-size: 26px;
    font-weight: 800;
    text-transform: uppercase;
}

.configuration__tab,
.configuration__tabs {
    -webkit-box-flex: 1;
    -webkit-box-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.configuration__tab {
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    background: 0;
    border: 0;
    border-bottom: 1px solid;
    border-image: linear-gradient(to right, #efff11, #247bff, #d320ca, #d30202) 47% 0%;
    color: #323232;
    cursor: pointer;
    -ms-flex: 1;
    flex: 1;
    font-size: 22px;
    font-style: italic;
    font-weight: 800;
    justify-content: center;
    padding: 0 0 20px;
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    white-space: nowrap;
}

.in-visible {
    visibility: hidden;
}

.configuration__tab.is-active {
    border-color: #e7e7e7;
    color: #e7e7e7;
}

.configuration__tab-contents {
    width: 100%;
}


.configuration__tab-contents:nth-child(2) {
    margin-top: 90px;
}

.configuration__grid {
    -webkit-box-flex: 1;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 100%;
    flex: 1 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;

}

.configuration__item {
    margin-top: 10px;
    -webkit-box-flex: 0;
    -ms-flex: 0 25%;
    flex: 0 25%;
    margin-bottom: 10px;
    text-align: center;
    max-width: 225px;
    z-index: 1;
    border-radius: 12px;
    padding-bottom: 20px;
}

@media screen and (max-width: 620px) {
    .configuration__item {
        padding: 10px;
        flex: 2;
    }
}

.configuration__item:hover {
    background: linear-gradient(-45deg, #ebe716, #fd39e3, #b610cc, #2863e4);
    background-size: 200% 200%;
    animation: gradient 10s ease infinite;
}



@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.configuration__item2 {
    margin-top: 10px;
    -webkit-box-flex: 0;
    -ms-flex: 0 25%;
    flex: 0 25%;
    text-align: center;

    z-index: 1;
    border-radius: 12px;
    padding-bottom: 20px;
    margin-bottom: 50px;
}

@media screen and (max-width: 620px) {
    .configuration__item2 {
        padding: 10px;
        flex: 4 25px;
    }
}

.configuration__item2:hover {
    background: linear-gradient(-45deg, #3984f5, #070000, #4207cc, #2863e4);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
}

.configuration__icon {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 110px;
    justify-content: center;
    position: relative;
    width: 100%;
    z-index: 1;
}

.configuration__icon2 {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 50px;
    justify-content: center;
    position: relative;
    width: 100%;
    z-index: 1;
}

.configuration__type {
    color: #f5f5f5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 10px;
    text-transform: uppercase;

}

.configuration__type2 {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.configuration__name {
    color: #e7e7e7;
    font-size: 12px;
    font-style: italic;
    font-weight: 700;
    text-align: center;
}

.configuration__name2 {
    color: #f94eff;
    font-size: 24px;
    font-style: italic;
    font-weight: 700;
    text-align: center;
}

.configuration__name22 {
    color: #f94eff;
    font-size: 34px;
    font-style: italic;
    font-weight: 700;
    text-align: center;
}

.configuration__discription {
    margin-top: 50px;

}

.text__p_d {
    text-align: start;
    font-size: 10px;
}

.text__p_d-inactive {
    visibility: hidden;
}

@media (max-width: 1024px) {

    .configuration__name2 {
        font-size: 32px;
    }

    .configuration__type,
    .configuration__name {
        font-size: 22px;
    }

    .configuration__type2 {
        font-size: 22px;
    }

    .configuration__type_ {
        font-size: 22px;
    }
}

.container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1200px;
    /* Ограничиваем максимальную ширину контейнера для лучшей симметрии */
}

.configuration__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.configuration__item,
.configuration__item2 {
    flex: 0 1 220px;
    /* Устанавливаем одинаковую ширину для всех элементов */

    max-width: 220px;
    /* Одинаковая максимальная ширина */
    text-align: center;
    border-radius: 12px;

    background-color: transparent;
    /* Добавляем фоновый цвет для лучшей визуальной гармонии */
}

.configuration__icon,
.configuration__icon2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    /* Одинаковая высота для всех иконок */
}

.configuration__tab,
.configuration__tabs {
    display: flex;
    justify-content: center;
    align-items: center;
}

.configuration__tab {
    flex: 1;
    padding: 10px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: .3s ease-in-out;
    text-align: center;
}

.configuration__tab.is-active {
    border-color: #e7e7e7;
    color: #e7e7e7;
}

@media screen and (max-width: 620px) {

    .configuration__item,
    .configuration__item2 {
        flex: 0 1 100%;
        /* Элементы занимают всю ширину экрана на мобильных устройствах */
        max-width: 100%;
    }
}