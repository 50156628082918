.popup__container {
    max-width: 430px;
    color: black;
    background: white;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0 4px 4px rgba(255, 255, 255, 0.25);
    position: relative;
}

@media screen and (max-width: 720px) {
    .popup__container {
        max-width: 90%;
    }
}