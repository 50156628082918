.popup__icon-caption {
    color: #000;
    margin: 40px 18px 50px;
    text-align: center;
    font-size: 20px;
    font-weight: 900;
    line-height: 25px;
}

@media screen and (min-width: 768px) {
    .popup__icon-caption {
        margin: 32px 36px 60px;
        font-size: 24px;
        line-height: 30px;
    }
}