.header {
    display: flex;
    margin-top: 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(84, 84, 84, 0.7);
    justify-content: space-around;
    align-items: center;
    position: relative;
    /* Чтобы псевдоэлемент был позиционирован относительно родителя */
}

.header::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    /* Толщина нижней границы */
    background: linear-gradient(to right, #efff11, #247bff, #d320ca, #d30202);
    background-size: 200% 100%;
    /* Больше для создания эффекта движения */
    animation: move-gradient 4s linear infinite;
    /* Анимация движения */
}

@keyframes move-gradient {
    0% {
        background-position: 0% 100%;
    }

    100% { 
        background-position: 100% 100%;
    }
}

@media (max-width: 1024px) {
    .header__balance {
        font-size: 14px;
    }
}

.header__balance_unactive {
    display: none;
}