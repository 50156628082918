 .accordion a {
     text-align: center;
     margin: auto;
     position: relative;
     display: -webkit-box;
     display: -webkit-flex;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-orient: vertical;
     -webkit-box-direction: normal;
     -webkit-flex-direction: column;
     -ms-flex-direction: column;
     flex-direction: column;
     width: 100%;
     padding: 10px;
     color: #7288a2;
     font-size: 20px;
     font-weight: 400;
     border-bottom: 1px solid #e5e5e5;
 }



 a {
     max-width: 70%;
     padding: 0;
 }

 .accordion a:hover,
 .accordion a:hover::after {
     cursor: pointer;
     color: #318cf5;
 }

 .accordion a::after {
     font-family: 'Ionicons';
     position: absolute;
     float: right;
     right: 1rem;
     font-size: 1rem;
     color: #7288a2;
     width: 30px;
     height: 30px;
     -webkit-border-radius: 50%;
     -moz-border-radius: 50%;
     border-radius: 50%;
     border: 1px solid #7288a2;
     text-align: center;
 }

 .accordion .content {
     opacity: 0;
     padding: 0 1rem;
     max-height: 0;
     border-bottom: 1px solid #e5e5e5;
     overflow: hidden;
     clear: both;
     -webkit-transition: all 0.2s ease 0.15s;
     -o-transition: all 0.2s ease 0.15s;
     transition: all 0.55s ease-out;
 }

 .accordion .content p {
     font-size: 18px;
     font-weight: 300;
     text-align: center;
 }

 .accordion .content.active {
     opacity: 1;
     padding: 1rem;
     max-height: 100%;
     -webkit-transition: all 0.35s ease 0.15s;
     -o-transition: all 0.35s ease 0.15s;
     transition: all 0.55s ease-out;
 }


 @media (max-width: 1024px) {
     .accordion a {
         text-align: center;
         margin: auto;
         position: relative;
         display: -webkit-box;
         display: -webkit-flex;
         display: -ms-flexbox;
         display: flex;
         -webkit-box-orient: vertical;
         -webkit-box-direction: normal;
         -webkit-flex-direction: column;
         -ms-flex-direction: column;
         flex-direction: column;
         width: 100%;
         padding: 10px;
         color: #7288a2;
         font-size: 15px;
         font-weight: 400;
         border-bottom: 1px solid #e5e5e5;
     }

     .accordion {
         margin-bottom: 10%;
     }

     a {
         max-width: 250px;
         padding: 0;
     }

     .accordion a:hover,
     .accordion a:hover::after {
         cursor: pointer;
         color: #318cf5;
     }

     .accordion a::after {
         position: absolute;
         float: right;
         right: 1rem;
         font-size: 1rem;
         color: #7288a2;
         width: 30px;
         height: 30px;
         -webkit-border-radius: 50%;
         -moz-border-radius: 50%;
         border-radius: 50%;
         border: 1px solid #7288a2;
         text-align: center;
     }

     .accordion .content {
         opacity: 0;
         padding: 0 1rem;
         max-height: 0;
         border-bottom: 1px solid #e5e5e5;
         overflow: hidden;
         clear: both;
         -webkit-transition: all 0.2s ease 0.15s;
         -o-transition: all 0.2s ease 0.15s;
         transition: all 0.55s ease-out;
     }

     .accordion .content p {
         font-size: 12px;
         font-weight: 300;
         text-align: center;
     }

     .accordion .content.active {
         opacity: 1;
         padding: 1rem;
         max-height: 100%;
         -webkit-transition: all 0.35s ease 0.15s;
         -o-transition: all 0.35s ease 0.15s;
         transition: all 0.55s ease-out;
     }

 }