@font-face {
  font-family: 'TC';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(TC.otf) format("woff2"),
    url(TC.otf) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(Inter-Regular.woff2) format('woff2'),
    url(Inter-Regular.woff) format('woff');

}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  color: black;
  font-display: swap;
  src: url(Inter-Black.woff2) format('woff2'),
    url(Inter-Black.woff) format('woff');
}