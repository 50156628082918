.popup__close {
    background-image: url(../../../images/svg/CloseIcon.svg);
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    border: none;
    font: inherit;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    top: -40px;
    right: -40px;
}

.popup__close:hover {
    opacity: 0.6;
}

@media screen and (max-width: 720px) {
    .popup__close {
        top: -38px;
        right: -1px;
        max-width: 20px;
        max-height: 20px;
        background-size: contain;
    }
}