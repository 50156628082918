/* Основные стили для страницы */
body {
  background-color: #1a1a1a;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

/* Логотип */
.logo {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 2px;
}

/* Навигационное меню */
.nav-list {
  display: flex;
  gap: 20px;
  list-style: none;
}

.nav-list li {
  font-size: 18px;
  cursor: pointer;
}

.nav-list li:hover {
  color: #f0f0f0;
}

/* Главный контент */
.main-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
}

.main-text {
  max-width: 40%;
}

.main-text h1 {
  font-size: 3vh;
  margin-bottom: 20px;
}

.main-text p {
  font-size: 24px;
  margin-bottom: 30px;
  color: #ccc;
}

/* Кнопка Забронировать */
.btn-primary {
  background-color: #ffffff;
  border: none;
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 30px;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #125794;
  color: white;
}

/* Изображение в главной секции */
.image-wrapper img {
  width: 500px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
}

/* Секция карточек */
.card-section {
  display: flex;
  justify-content: space-around;
  padding: 40px;
  align-items: stretch;
}

/* Стили для Link */
.card-link {
  text-decoration: none;
  color: inherit;
  width: 20%;
  display: flex;
  justify-content: center;
  transition: transform 0.3s ease;
}

/* Общие стили для карточек */
.card {
  background-color: #000000;
  border-radius: 15px;
  padding: 20px;
  width: 100%;
  text-align: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-yellow {
  box-shadow: 0 0 11px #ffff00, 0 0 2px #ffff00, 0 0 3px #ffff00;
}

/* Разные цвета подсветки для карточек */
.card-yellow:hover {
  box-shadow: 0 0 5px #ffff00, 0 0 15px #ffff00, 0 0 35px #ffff00;
}

.card-blue {
  box-shadow: 0 0 11px #015099, 0 0 2px #015099, 0 0 3px #015099;
}

.card-blue:hover {
  box-shadow: 0 0 15px #015099, 0 0 30px #015099, 0 0 45px #015099;
}

.card-pink {
  box-shadow: 0 0 11px #ff00ff, 0 0 2px #ff00ff, 0 0 3px #ff00ff;
}

.card-pink:hover {
  box-shadow: 0 0 15px #ff00ff, 0 0 30px #ff00ff, 0 0 45px #ff00ff;
}

.card-green {
  box-shadow: 0 0 11px #bf0543, 0 0 2px #bf0543, 0 0 3px #bf0543;
}

.card-green:hover {
  box-shadow: 0 0 15px #bf0543, 0 0 30px #bf0543, 0 0 45px #bf0543;
}

/* Изображение в карточке */
.card-image {
  object-fit: contain;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}

/* Заголовок карточки */
.card h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

/* Описание карточки */
.card p {
  font-size: 16px;
  color: #bbb;
  margin-bottom: 20px;
}

/* Кнопка в карточке */
.btn-secondary {
  background-color: white;
  color: black;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 20px;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  min-height: 50px;
}

.btn-secondary:hover {
  background-color: #aa0b8a;
  color: white;
}

/* Медиазапросы */
@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .main-text {
    max-width: 100%;
    text-align: center;
  }

  .image-wrapper img {
    margin: auto;
    margin-top: 10%;
    width: 100%;
  }

  .nav-list {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .card-link {
    width: 45%;
  }
}

@media (max-width: 480px) {


  .main-content {
    padding: 10px;
  }

  .btn-primary {
    padding: 10px 20px;
    font-size: 16px;
  }

  .card-section {
    flex-direction: column;
    align-items: center;
  }

  .card-link {
    width: 90%;
    margin-bottom: 20px;
  }

  .btn-secondary {
    padding: 10px;
    font-size: 14px;
  }
}