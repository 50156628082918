.page {
  margin: 0 auto;
  min-width: 320px;
  max-width: 1280px;
  justify-content: center;
  font-family: "TC", Arial, Helvetica, sans-serif;
  background-color: black;
  color: rgb(255, 255, 255);
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  overflow: hidden;
}
