.tournament__columns {
    display: flex;
    flex-wrap: wrap; /* Добавляем перенос */
    gap: 20px; /* Добавьте отступ между элементами, если нужно */
    justify-content: center; /* Центруем элементы, если это нужно */
}

@media screen and (max-width:620px) {
    .tournament__columns {
        display: flex;
        flex-direction: column;
        align-items: center; /* Центруем элементы на мобильных устройствах */
    }
}