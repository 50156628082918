/* Стили для загрузочного экрана */
.loading-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1a1a1a;
    color: #fff;
    font-size: 24px;
}

.spinner {
    margin-top: 20px;
    width: 50px;
    height: 50px;
    border: 6px solid #ccc;
    border-top-color: #0b58a5;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}


@media (max-width: 1024px) {
    .loading-screen {
        max-width: 100%;
    }

    .loading-screen_img {
        max-width: 80%;
    }

}