.lk_row {
    display: flex;
}

/* Медиазапросы */
@media (max-width: 768px) {
    .lk_row {
        flex-direction: column;
    }
}

.lk_box {
    display: flex;
    margin: auto;
    flex-direction: column;
    padding: 4%;
    border: 1px solid;
    border-image: linear-gradient(to right, #efff11, #247bff, #d320ca, #d30202) 47% 47%;
    align-items: center;
}

.lk-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.lk-box {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.promo-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.promo-message {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
    text-align: center;
}

/* Стили для input */
.promo-input {
    width: 70%;
    /* Широкое поле ввода */
    padding: 10px;
    /* Внутренние отступы */
    margin-right: 10px;
    /* Отступ справа для кнопки */
    font-size: 16px;
    /* Увеличенный размер шрифта */
    border: 1px solid #ccc;
    /* Легкая граница */
    border-radius: 4px;
    /* Закругленные углы */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Легкая тень */
    outline: none;
    /* Убираем синюю обводку при фокусе */
    transition: border-color 0.3s ease;
    /* Плавное изменение границы */
    font-family: 'roboto';
    margin-bottom: 5%;
    text-align: center;
}

.promo-input:focus {
    border-color: #28a745;
    /* Изменение цвета границы при фокусе */
}

/* Стили для кнопки */
.promo-button {
    padding: 10px 20px;
    /* Внутренние отступы */
    background-color: #247bff;
    /* Зеленый цвет кнопки */
    color: white;
    /* Белый текст */
    font-size: 16px;
    /* Размер шрифта */
    border: none;
    /* Убираем стандартную границу */
    border-radius: 4px;
    /* Закругленные углы */
    cursor: pointer;
    /* Курсор указывает на возможность клика */
    transition: background-color 0.3s ease;
    /* Плавное изменение цвета при наведении */
}

.promo-button:hover {
    background-color: #218838;
    /* Более темный зеленый при наведении */
}



.stats {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.free-night-box {
    max-width: 380px;
    margin: auto;
    text-align: center;
    font-size: 15px;
}

.error-message {
    color: red;
    font-weight: bold;
}