.zal__li_text {
    margin: auto;
    outline: none;
    /* Для синий ободки */
    border: 0;
    background: transparent;
    font-size: 25px;
    cursor: pointer;
    text-align: center;
    margin: 0;
    padding: 0;
}



.zal__li_time_active {
    margin-top: 15%;
    background-color: transparent;
    color: white;
    font-size: 15px;
    border: 0;
    align-self: center;
}

.zal__standart .zal__li_text {
    color: #f4c542;
    text-shadow:
        0 0 7px #f4c542,
        0 0 10px #f4c542,
        0 0 151px #f4c542;
}

.zal__comfort .zal__li_text {
    color: rgb(32, 108, 179);
    text-shadow:
        0 0 7px blue,
        0 0 10px blue,
        0 0 82px blue,
        0 0 102px blue,
        0 0 151px blue;
}

.zal__comfort-plus .zal__li_text {
    color: rgb(240, 46, 247);
    text-shadow:
        0 0 7px rgb(237, 63, 253),
        0 0 181px rgb(237, 63, 253);
}

.zal__vip .zal__li_text {
    color: rgb(242, 23, 23);
    text-shadow:
        0 0 7px #b40063,
        0 0 10px rgb(180, 0, 99),
        0 0 151px rgb(180, 0, 99);
}


.zal__ps_li_text {
    color: rgb(255, 255, 255);
    background: transparent;
    margin: auto;
    font-size: 25px;
    outline: none;
    /* Для синий ободки */
    border: 0;
    cursor: pointer;
    background: transparent;

    text-shadow:
        0 0 7px rgb(237, 63, 253),
        0 0 151px rgb(237, 63, 253);

}

@media (max-width: 1024px) {
    .zal__li_text {
        font-size: 12px;
    }

    .zal__li_time_active {
        font-size: 6px;
    }

    .zal__ps_li_text {
        font-size: 8px;
    }
}

@media (max-width: 410px) {
    .zal__li_text {
        font-size: 8px;
    }

    .zal__li_time_active {
        font-size: 5px;
    }


}