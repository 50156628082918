.popup__text {
    font-weight: 900;
    font-size: 24px;
    line-height: 100%;
    padding: 0;
    margin: 36px 0 52px 0;
}

@media screen and (max-width: 720px) {
    .popup__text {
        margin: 20px 0 75px 0;
        font-size: 18px;
        line-height: 22px;
    }
}