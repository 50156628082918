.tournament {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tournament_div {
    max-width: 350px;
    display: flex;
    flex-direction: column;
    text-align: center;
}