.header__logo {
    padding: 0;
    margin: 0;
    max-width: 125px;
    max-height: 125px;
}

@media screen and (max-width: 1024px) {
    .header__logo {
        max-width: 80px;
        max-height: 80px;
    }
}