.title {
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    border: none;
    text-decoration: none;
    color: rgb(255, 255, 255);
    animation: pulsate 2s ease-in-out infinite alternate;
    padding-bottom: 60px;
    text-align: center;
}

.text_discription {
    font-size: 15px;
}

.text_btn {
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    border: none;
    text-decoration: none;
    color: rgb(255, 255, 255);
    animation: pulsate 1s ease-in-out infinite alternate;
}

@keyframes pulsate {
    100% {
        /* Larger blur radius */
        text-shadow:
            0 0 4px #0859a0,
            0 0 20px #0859a0,
            0 0 30px #0859a0;
    }

    0% {
        /* A slightly smaller blur radius */
        text-shadow:
            0 0 4px #0859a0,
            0 0 20px #0859a0,
            0 0 30px #0859a0;
    }
}

.text__p {
    text-align: center;
    font-size: 24px;
}