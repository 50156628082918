.about_img {
    max-width: fit-content;
}

@media screen and (max-width: 620px) {
    .about_img {
        display: none;
    }

    .about_img:last-child {
        margin-left: 40px;
    }
}