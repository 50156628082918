.zal {
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.standart_vip_comfort {
  margin: auto;
}

/* Стиль для списка категорий */
.zal-categories {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  font-size: 12px;
}

@media (max-width: 1024px) {
  .zal-categories {
    display: flex;
    flex-direction: column;
    align-self: center;
  }
}

.zal-category {
  display: flex;
  align-items: center;
}

.zal-category-color {
  display: inline-block;
  width: 25px;
  height: 35px;
  margin-right: 10px;
  border-radius: 25px;
}

/* Цвета для каждой категории */
.standard {
  background-color: #f4c542;
  /* Желтый для Стандарта */
}

.comfort {
  background-color: #42aaf4;
  /* Синий для Комфорт */
}

.comfort-plus {
  background-color: #e729a4;
  /* Розовый для Комфорт + */
}

.vip {
  background-color: #e31919;
  /* Золотой для VIP */
}

.zal__vip {
  border: 1px solid rgb(223, 50, 50);
  margin: 0;
  display: flex;
  padding: 0;
  flex-direction: column;
}

.zal__reception-komfortplus-ps {
  margin-top: 10%;
  display: flex;
}

/* Блоки */

.zal__reception {
  display: flex;
  align-self: center;
}

.qwe {
  display: flex;
  flex-wrap: wrap;
}

.zal__std-vip {
  display: flex;
  flex-direction: row;
}


/* Стандарт */
.zal__standart {
  display: flex;
  margin: 0;
  padding: 0;
  max-width: min-content;
}

.standart__13-17 {
  padding: 0;
  align-self: flex-end;
  display: flex;
  flex-direction: row-reverse;
  margin-left: auto;
  /* Push the block to the right */
}

.standart__4-12 {
  padding: 0;
  display: flex;
  flex-direction: row;
}

.standart__1-3 {
  padding: 0;
  display: flex;
  flex-direction: column-reverse;
}

/* Комфорт */
.zal__comfort {
  width: fit-content;
  padding: 0;
  margin: 0;
  margin-left: auto;
  margin-top: 2px;
}

.zal__comfort_18-27 {
  display: flex;
  padding: 0;
  flex-direction: row;
}

/* Комфорт+ */
.zal__comfort-plus {
  display: flex;
  padding: 0;
  flex-direction: column;
  margin: 5%;
  margin-top: 0;
  margin-bottom: 0;
}

.zal__comfortplus_32-35 {
  padding: 0;
  display: flex;
  width: fit-content;
  flex-direction: column;
}

.zal__comfortplus_28-35 {
  display: flex;
}

.zal__comfortplus_28-31 {
  padding: 0;
  display: flex;
  width: fit-content;
  flex-direction: column-reverse;
}

/* VIP */
.zal__vip_41-45 {
  padding: 0;
  display: flex;
  width: fit-content;
  flex-direction: row-reverse;
}

.zal__vip_36-40 {
  padding: 0;
  display: flex;
  width: fit-content;
  margin-bottom: 40%;
}

/* PS */
.zal__ps {
  display: flex;
  margin: 0;
  padding-left: 2%;

}

.zal__ps_li {
  display: flex;
  border: 1px solid rgb(162, 2, 255);
  padding-left: 25px;
  padding-right: 25px;
  flex-direction: column;
}

.zal__reception,
.text_btn {
  font-size: 18px;
}

@media (max-width: 1024px) {
  .zal__comfort {
    align-self: flex-end;
  }

  .zal__ps_li {
    padding: 1%;
  }

  .zal__reception,
  .text_btn {
    font-size: 12px;
  }
}