.header__bar_menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: .8s;
    border-radius: 10px;
    padding: 20px;
    background-color: rgba(0, 0, 0, 1);
    z-index: 1;
}

.header__bar_menu_active {
    transform: translate(0px, 30px);
    visibility: visible;
    opacity: 1;
}