.roulette-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message {
  text-align: center;
  width: 500px;
}

.spin-button {
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
  color: wheat;
  font-size: 18px;
  cursor: pointer;
  text-shadow: 0 0 5px #fff;
  transition: all 0.3s ease;
  max-width: 80%;
  background-color: unset;
  border: 1px solid white;
}

.spin-button:hover {
  text-shadow: 0 0 10px #fff;
}

/* Модальное окно */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #1e1e1e;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 10px #247bff;
}

.modal-content h3 {
  color: #00ff00;
  font-size: 28px;
  margin-bottom: 20px;
}

.modal-content p {
  color: #ffffff;
  font-size: 20px;
}

.modal-button {
  padding: 10px 20px;
  background-color: black;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  margin-top: 20px;
}

.modal-button:hover {
  background-color: #247bff;
  text-shadow: 0 0 10px #fff;
}