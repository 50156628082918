.footer {
    background-color: #000;
    padding: 20px;
    color: #fff;
    text-align: center;
    border-top: 1px solid rgba(84, 84, 84, 0.7);
    position: relative;
}

.footer::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    /* Толщина нижней границы */
    background: linear-gradient(to right, #efff11, #247bff, #d320ca, #d30202);
    background-size: 200% 100%;
    /* Больше для создания эффекта движения */
    animation: move-gradient 4s linear infinite;
    /* Анимация движения */
}

.footer-container {
    display: flex;
    flex-direction: row;
    /* Горизонтальная компоновка на больших экранах */
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    /* Элементы будут переноситься при необходимости */
    max-width: 1200px;
    margin: 0 auto;
    /* Центрируем футер */
}

.footer-logo img {
    width: 100px;
    margin-bottom: 0;
}

.footer-links {
    display: flex;
    gap: 20px;
    align-items: center;
    width: 50%;
}

.footer-links a {
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: color 0.3s ease, transform 0.3s ease;
}

.footer-links a:hover {
    color: #1b84c1;
    transform: scale(1.1);
}

.footer-developer {
    display: flex;
    align-items: center;
}

span {
    padding-right: 10px;
}

.footer-developer a {
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: color 0.3s ease, transform 0.3s ease;
}

.footer-developer a:hover {
    color: #00f2ff;
    transform: scale(1.1);
}

.icon {
    font-size: 24px;
    transition: color 0.3s ease, transform 0.3s ease;
}

.icon:hover {
    color: #0e55a6;
    transform: rotate(360deg);
}

/* Адаптивные стили для мобильных устройств */
@media (max-width: 768px) {
    .footer {
        font-size: 12px;
        margin-top: 20%;
        margin-bottom: 20%;
    }

    .footer-container {
        flex-direction: column;
        /* На узких экранах элементы будут в колонку */
        align-items: center;
        /* Выравнивание по центру */
        gap: 20px;
    }

    .footer-links {
        width: 100%;
        flex-direction: row;
        justify-content: center;
        /* Ссылки будут отображаться вертикально */
    }
}

.dode {
    animation: blinker 1s linear infinite;
    margin: auto;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}