.login__submit {
    padding: 0;
    cursor: pointer;
    border: none;
    border-radius: 2px;
    width: 358px;
    height: 50px;
    font-weight: 400;
    font-size: 18px;
    line-height: normal;
    text-align: center;
    margin-top: 216px;
} 

.login__submit:hover {
    opacity: 0.6000000238418579;
}