.tournament__photo {
    width: 180px;
    align-self: flex-start;
    transition: 1s;
    border-radius: 20px;
}

.tournament__photo:hover {
    transform: scale(1.2);
}
