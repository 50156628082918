.popup {
    display: flex;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s linear;
    position: fixed;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    flex-direction: column;
    align-items: flex-end;
}