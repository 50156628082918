/* Отображаем панель только на экранах до 800px */
@media (max-width: 800px) {
  .bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    /* Меню на всю ширину экрана */
    background-color: black;
    /* Белый фон меню */
    display: flex;
    padding: 10px 0;
    /* Паддинг для уменьшения высоты меню */
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    /* Тень для меню */
    /* Прозрачная граница, чтобы задать обводку */

    z-index: 10000;

  }

  .nav-item {
    color: rgb(255, 255, 255);
    /* Чёрный текст и иконки */
    text-decoration: none;
    text-align: center;
    flex: 1;
    transition: background-color 0.3s ease;
    border-radius: 5px;
    /* Отступы между кнопками */
    padding: 10px 0;
    /* Внутренний отступ внутри кнопок */
  }

  .nav-item .icon {
    font-size: 20px;
    /* Размер иконок */
  }

  .nav-item .text {
    font-size: 9px;
    /* Размер текста */
  }

  .nav-item:hover {
    background-color: rgba(255, 254, 255, 0.05);
    /* Ещё более прозрачный фон при наведении */
  }
}

/* На экранах больше 800px скрываем меню */
@media (min-width: 801px) {
  .bottom-nav {
    display: none;
  }
}