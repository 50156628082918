.zal__li {
    display: flex;
    flex-direction: column;
    width: auto;
    list-style-type: none;
    width: 70px;
    height: 70px;
}

@media (max-width: 1024px) {
    .zal__li {
        width: 25px;
        height: 25px;
    }
}

@media (max-width: 410px) {
    .zal__li {
        width: 20px;
        height: 20px;
    }
}

.zal__standart .zal__li {
    border: 1px solid yellow;
}

.zal__comfort .zal__li {
    border: 1px solid blue;
}

.zal__comfort-plus .zal__li {
    border: 1px solid rgb(204, 0, 187);
}

.zal__vip .zal__li {
    border: 1px solid red;
}