#menu {
    z-index: 2;
    visibility: hidden;
}

@media screen and (max-width: 720px) {
    #menu {
        visibility: visible;
        max-width: 520px;
    }
}

#menu-bar {
    width: 45px;
    height: 40px;
    margin: 30px 0 20px 20px;
    cursor: pointer;
}

.bar {
    height: 5px;
    width: 100%;
    background-color: #0f8de0;
    display: block;
    border-radius: 5px;
    transition: 0.3s ease;
}

#bar1 {
    transform: translateY(-4px);
}

#bar3 {
    transform: translateY(4px);
}

.nav {
    display: none;
}

.nav ul {
    padding: 0 22px;
}

.nav li {
    list-style: none;
    padding: 12px 0;
}

.nav li a {
    color: white;
    font-size: 20px;
    text-decoration: none;
}

.menu-bg,
#menu {
    top: 0;
    left: 0;
    position: absolute;
}

.menu-bg {
    z-index: 1;
    width: 0;
    height: 0;
    margin: 130px 0 20px 20px;
    background: radial-gradient(circle, #0598dc, #940aca);
    border-radius: 50%;
    transition: 0.1s ease;
}

.change {
    display: block;
}

.change .bar {
    background-color: white;
}

.change #bar1 {
    transform: translateY(4px) rotateZ(-45deg);
}

.change #bar2 {
    opacity: 0;
}

.change #bar3 {
    transform: translateY(-6px) rotateZ(45deg);
}

.change-bg {
    width: 520px;
    height: 460px;
    transform: translate(-60%, -30%);
}