.popup__icon {
    display: flex;
    margin: 50px auto 0;
    width: 120px;
    max-height: 120px;
}

@media screen and (min-width: 768px) {
    .popup__icon {
        margin: 60px auto 0;
    }
}